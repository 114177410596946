<template>
  <div class="layer_08">
    <div class="layer_top">
      <h2 class="layer_top_title">관리자 {{ id ? "수정" : "등록" }}</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="layer_03_box">
      <form action="#">
        <table class="layer_03_table">
          <colgroup>
            <col style="width: 107px" />
            <col style="width: 440px" />
            <col style="width: 120px" />
            <col style="width: 500px" />
          </colgroup>
          <!--  <tr class="layer_tbl">
           <td class="pb_27">
              <base-select
                :options="adminState"
                v-model="selectedAdmin"
                :picked="userState.name"
                placeholder="회원상태 선택"
              />
            </td>
          </tr>-->
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_27">아이디</td>
            <td class="pb_27">
              <input
                type="text"
                class="input_box_07"
                v-if="!id"
                v-model="loginid"
              />
              <span v-else>{{ loginid }}</span>
            </td>
            <td class="layer_tbl_title pb_27">비밀번호</td>
            <td class="pb_27">
              <input
                type="password"
                class="input_box_07 mr_10"
                v-model="pass"
              />
              <base-button v-if="id" type="upload" @click="resetPass"
                >초기화</base-button
              >
            </td>
          </tr>
          <tr class="layer_tbl">
            <td class="layer_tbl_title pb_27">이름</td>
            <td class="pb_27">
              <input type="text" class="input_box_06" v-model="name" />
            </td>
            <td class="layer_tbl_title pb_27">이메일</td>
            <td class="pb_27">
              <input type="text" class="input_box_07" v-model="email" />
            </td>
          </tr>
          <!-- <tr class="layer_tbl">
            <td class="layer_tbl_title pb_15 pb_27">권한</td>
            <td colspan="3" class="pb_15 pb_27">
              <input type="checkbox" id="statistics" class="big_check ml_0" />
              <label for="statistics" class="check_label_02">통계관리</label>
              <input type="checkbox" id="stamp_manage" class="big_check" />
              <label for="stamp_manage" class="check_label_02"
                >스탬프 관리</label
              >
              <input type="checkbox" id="community_manage" class="big_check" />
              <label for="community_manage" class="check_label_02"
                >커뮤니티 관리</label
              >
              <input type="checkbox" id="membership_m" class="big_check" />
              <label for="membership_m" class="check_label_02">회원관리</label>
            </td>
          </tr> -->
          <!-- <tr class="layer_tbl">
            <td class="layer_tbl_title pb_27">생년월일</td>
            <td class="pb_27">
              <date-picker
                v-model="birthday"
                :popover="{ visibility: 'click' }"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    type="text"
                    :value="inputValue"
                    v-on="inputEvents"
                    class="dt_base_input"
                    readonly
                  />
                </template>
              </date-picker>
            </td>

            <td class="layer_tbl_title pb_27">성별</td>
            <td class="pb_27">
              <base-select
                :options="genders"
                v-model="selectedGender"
                placeholder="상태 선택"
              />
            </td>
          </tr> -->
          <tr class="layer_tbl">
            <td class="layer_tbl_title">전화번호</td>
            <td>
              <input type="text" class="input_box_07" v-model="tel" />
            </td>
            <!-- <td class="layer_tbl_title">결혼여부</td>
            <td>
              <base-select
                :options="marriages"
                v-model="selectedMarriage"
                placeholder="상태 선택"
              />
            </td> -->
            <td class="layer_tbl_title">권한</td>
            <td>
              <base-select
                :options="roles"
                v-model="selectedRole"
                placeholder="권한 선택"
              />
            </td>
          </tr>
        </table>
      </form>
      <div class="btn_center_wrapper">
        <base-button type="primary-lg" @click="edit">{{
          id ? "수정" : "등록"
        }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseSelect from "@/components/core/BaseSelect.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import client from "api-client";
import Dialog from "@/components/Dialog.vue";
export default {
  components: {
    BaseSelect,
    BaseButton,
  },
  props: {
    id: Number,
    beforeEmail: String,
  },
  data() {
    return {
      name: "",
      email: "",
      tel: "",
      loginid: "",
      birthday: "",
      pass: "",
      userState: { name: "", value: "" },
      genders: [
        { name: "선택안함", value: "0" },
        { name: "남자", value: "1" },
        { name: "여자", value: "2" },
        { name: "기타", value: "3" },
      ],
      selectedGender: "",
      marriages: [
        { name: "선택안함", value: "0" },
        { name: "기혼", value: "1" },
        { name: "미혼", value: "2" },
      ],
      selectedMarriage: "",
      selectedAdmin: "",
      adminState: [
        { name: "승인", value: "0" },
        { name: "탈퇴", value: "1" },
        { name: "일시 정지", value: "2" },
      ],
      roles: [
        { name: "관리자", value: "ADMIN_MANAGER" },
        { name: "편집자", value: "ADMIN_EDITOR" },
        { name: "애널리스트", value: "ADMIN_ANALYST" },
        { name: "뷰어", value: "ADMIN_VIEWER" },
      ],
      selectedRole: "",
    };
  },
  created() {
    this.fetch();
  },
  watch: {
    birthday() {
      console.log(this.birthday);
    },
  },
  methods: {
    async fetch() {
      if (this.id) {
        await client.userFindone(this.id).then(
          (response) => {
            const { data } = response;
            this.name = data.name;
            this.loginid = data.loginid;
            this.email = data.email;
            this.birthday = data.birthday;
            this.selectedGender = data.gender;
            this.selectedMarriage = data.marriage;
            this.tel = data.tel;
            this.pass = data.pass;
            this.selectedRole = data.role;

            /* TODO 관리자 승인상태
            if (data.state) {
              switch (data.state) {
                case "승인":
                  this.userState = { name: "승인", value: 1 };
                  break;
                case "탈퇴":
                  this.userState = { name: "탈퇴", value: 9 };
                  break;
                case "일시정지":
                  this.userState = { name: "일시정지", value: "일시정지" };
                  break;
              }
            }
            */
          },
          (error) => {
            console.log(error);
          }
        );
      }
    },
    resetPass() {
      this.pass = this.loginid;
    },
    async edit() {
      // if (!this.selectedAdmin) {
      //   alert("회원 상태를 선택해주세요");
      //   } else
      if (!this.loginid) {
        alert("아이디를 입력해주세요");
      } else if (!this.id && !this.pass) {
        alert("비밀번호를 입력해주세요");
      } else if (!this.email) {
        alert("이메일을 입력해주세요");
      } else if (!this.selectedRole) {
        alert("권한을 설정해주세요");
      } else {
        if (this.id) {
          console.log(this.email != this.beforeEmail);
          let params = {
            pass: this.pass != "" ? this.pass : undefined,
            name: this.name,
            email: this.email != this.beforeEmail ? this.email : undefined,
            role: this.selectedRole,
            birthday: this.$date(new Date(this.birthday)).format("YYYY-MM-DD"),
            gender: this.selectedGender,
            marriage: this.selectedMarriage,
            tel: this.tel,
          };
          await client.adminModify(this.id, params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "계정이 정상적으로 수정되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              console.log(error);
            }
          );
        } else {
          let params = {
            loginid: this.loginid,
            pass: this.pass,
            name: this.name,
            email: this.email,
            role: this.selectedRole,
            birthday: this.birthday,
            gender: this.selectedGender,
            marriage: this.selectedMarriage,
            tel: this.tel,
          };
          console.log(params);
          await client.adminAdd(params).then(
            (response) => {
              console.log(response);
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: "계정이 정상적으로 등록되었습니다.",
                },
                {
                  width: 400,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
              this.$emit("close", { refresh: true });
            },
            (error) => {
              const { data } = error.response;
              let message = data.message;
              this.$modal.show(
                Dialog,
                {
                  title: "알림",
                  content: message,
                },
                {
                  adaptive: true,
                  width: "90%",
                  maxWidth: 600,
                  height: "auto",
                },
                {
                  "before-open": () => {},
                  "before-close": () => {},
                }
              );
            }
          );
        }
      }
    },
  },
};
</script>
