<template>
  <div class="manage_layer_02">
    <div class="layer_top">
      <h2 class="layer_top_title">관리자</h2>
      <span class="btn_close" @click="$emit('close')">
        <img
          src="@/assets/img/close.png"
          style="width: 15px; height: 15px"
          alt="닫기"
        />
      </span>
    </div>
    <div class="manage_layer_box_02">
      <form>
        <table>
          <colgroup>
            <col style="width: 128px" />
            <col style="width: 320px" />
            <col style="width: 128px" />
            <col style="width: 320px" />
          </colgroup>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">이름</td>
            <td class="box_style_02">
              {{ name }}
            </td>
            <td class="box_style_02 list_title_style">아이디</td>
            <td class="box_style_02">
              {{ loginid }}
            </td>
          </tr>
          <tr class="line_style">
            <td class="box_style_02 list_title_style">이메일</td>
            <td class="box_style_02">{{ email }}</td>
            <td class="box_style_02 list_title_style">생년월일</td>
            <td class="box_style_02">{{ birthday }}</td>
          </tr>
          <!-- <tr class="line_style">
            <td class="box_style_02 list_title_style">성별</td>
            <td class="box_style_02">{{ gender }}</td>
            <td class="box_style_02 list_title_style">결혼여부</td>
            <td class="box_style_02">{{ marriage }}</td>
          </tr> -->
          <tr class="line_style">
            <td class="box_style_02 list_title_style">전화번호</td>
            <td class="box_style_02">{{ tel }}</td>
            <td class="box_style_02 list_title_style">권한</td>
            <td class="box_style_02">{{ role }}</td>
          </tr>
        </table>
        <div class="btn_center_wrapper">
          <base-button type="primary-lg" @click="$emit('close')"
            >확인</base-button
          >
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import client from "api-client";
import BaseButton from "./core/BaseButton.vue";
export default {
  components: { BaseButton },
  props: {
    id: {
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      name: "",
      loginid: "",
      email: "",
      birthday: "",
      gender: "",
      marriage: "",
      tel: "",
      role: "",
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      await client.userFindone(this.id).then(
        (response) => {
          const { data } = response;
          this.name = data.name;
          this.loginid = data.loginid;
          this.email = data.email;
          this.birthday = data.birthday ? data.birthday : "-";
          this.gender =
            data.gender == 0
              ? "선택안함"
              : data.gender == 1
              ? "남자"
              : data.gender == 2
              ? "여자"
              : data.gender == 3
              ? "기타"
              : "-";
          this.marriage =
            data.marriage == 0
              ? "선택안함"
              : data.marriage == 1
              ? "기혼"
              : data.marriage == 2
              ? "미혼"
              : "-";
          this.tel = data.tel ? data.tel : "-";
          switch (data.role) {
            case "ADMIN_MANAGER":
              this.role = "관리자";
              break;
            case "ADMIN_EDITOR":
              this.role = "편집자";
              break;
            case "ADMIN_ANALYST":
              this.role = "애널리스트";
              break;
            case "ADMIN_VIEWER":
              this.role = "뷰어";
              break;
            default:
              this.role = "사용자";
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },
  },
};
</script>
